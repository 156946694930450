  .editor-wrapper {
    width: 100%;
    border: 1px solid gray;
    background: #fff;
    padding: 0.5rem;
    border-radius: 5px;
  }

  .editor-container {
    width: 100%;
    border-top: 1px solid gray;
    margin-top: 8px;
    padding: 0.5rem;
  }

  .toolbar-grid {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 1fr 1fr;
    gap: 2px
  }

  button {
    display: flex;
    margin-right: 8px;
    font-size: 1rem;
    padding: 1px 4px 1px 4px;
    border: 1px solid gray;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    background: #fff;
    cursor: pointer;

  }

  .superFancyBlockquote {
    color: #999;
    background: #fff;
    font-family: "Hoefler Text", Georgia, serif;
    font-style: italic;
    /* border-left: 2px solid #999; */
    padding-left: 10px;
  }

  .codeBlock {
    font-family: fira-code, monospace;
    font-size: inherit;
    background: gray;
    font-style: italic;
    word-wrap: normal;
    word-wrap: break-word;
    box-decoration-break: slice;
    padding: 0.3rem 2rem;
    border-radius: 0.2rem;
  }

  .leftAlign {
    text-align: left;
  }

  .rightAlign {
    text-align: right;
  }

  .centerAlign {
    text-align: center;
  }

  .justifyAlign {
    text-align: justify;
  }