* {
    font-family: 'Poppins', sans-serif !important;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none !important;
}

/* ----------------------------------- */

div.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(16 16 16 / 75%);
    z-index: 999999;
}

.loaderSuccess {
    width: 50px;
    aspect-ratio: 1;
    display: grid;
    border: 4px solid #34bf3d92;
    border-radius: 50%;
    border-color: #34bf3d92 #0000;
    animation: l16 1s infinite linear;
}

.loaderSuccess::before,
.loaderSuccess::after {
    content: "";
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
}

.loaderSuccess::before {
    border-color: #2ecf15 #0000;
    animation: inherit;
    animation-duration: .5s;
    animation-direction: reverse;
}

.loaderSuccess::after {
    margin: 8px;
}

/* ----------------------------------- */
div.loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(16, 16, 16, 0.75);
    z-index: 999999;
}

/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    aspect-ratio: 1;
    display: grid;
    border: 4px solid #0000;
    border-radius: 50%;
    border-color: #ccc #0000;
    animation: l16 1s infinite linear;
}

.loader::before,
.loader::after {
    content: "";
    grid-area: 1/1;
    margin: 2px;
    border: inherit;
    border-radius: 50%;
}

.loader::before {
    border-color: #8488ff #0000;
    animation: inherit;
    animation-duration: .5s;
    animation-direction: reverse;
}

.loader::after {
    margin: 8px;
}

audio::-webkit-media-controls-panel {
    background-color: #bfd8ff;
}

audio {
    filter: sepia(20%) saturate(70%) grayscale(5) contrast(99%) invert(12%);
    /* width: 200px; */
    height: 25px;
}

.audioPlayer {
    display: block;
    border-radius: 20px;
    color: white;
}

.MuiFab-sizeSmall {
    height: 32px !important;
    min-height: 32px !important;
    width: 32px !important;
}

@keyframes l16 {
    100% {
        transform: rotate(1turn)
    }
}

::-webkit-scrollbar {
    width: 3px;
    height: 5px;
}

::-webkit-scrollbar-track {
    background: rgb(227, 235, 255);
}

::-webkit-scrollbar-thumb {
    background: #5D5FEF;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
    background: #5D5FEF;
}

.swal2-container {
    z-index: 10000;
    padding: 0;
    /* Adjust the z-index as needed */
}

.swal2-show {
    padding: 0px 0px 6px 0px !important;
    animation: "swal2-show 3.3s !important"
}

div:where(.swal2-icon) .swal2-icon-content {
    font-size: 2.75rem;
}

div:where(.swal2-container) h2:where(.swal2-title) {
    padding: 0 !important;
    font-size: 1.2rem !important;
    font-weight: 400 !important;
}

div:where(.swal2-container) button:where(.swal2-styled) {
    padding: 0.5em 1.5em !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color: #7066e0 !important;
    font-size: 0.8rem !important
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel {
    background-color: #909090 !important;
    font-size: 0.8rem !important
}

div:where(.swal2-icon).swal2-error.swal2-icon-show {
    height: 75px;
    width: 79px;
}

div:where(.swal2-container) .swal2-html-container {
    margin: 0.5em 0.6em 0.5em;
}

/* div:where(.swal2-icon){
    height: 4em !important;
    width: 4em !important;
} */
div:where(.swal2-icon) {
    margin: 0.5em auto .6em !important;
    color: rgb(237, 113, 113) !important;
    border-color: rgb(237, 113, 113) !important;
}

.swal2-x-mark-line-right {
    background-color: rgb(239, 96, 96) !important;
}

.swal2-x-mark-line-left {
    background-color: rgb(239, 96, 96) !important;
}

/* .MuiButtonBase-root {
    padding: 0px !important;
} */


@-webkit-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-ms-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes uil-ring-anim {
    0% {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


.uil-ring-css {
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100px;
    height: 100px;
}

.uil-ring-css>div {
    position: absolute;
    display: block;
    width: 100px;
    height: 100px;
    top: 20px;
    left: 20px;
    border-radius: 100px;
    box-shadow: 5px 8px 5px 0 rgb(66, 68, 195);
    -ms-animation: uil-ring-anim 1s linear infinite;
    -moz-animation: uil-ring-anim 1s linear infinite;
    -webkit-animation: uil-ring-anim 1s linear infinite;
    -o-animation: uil-ring-anim 1s linear infinite;
    animation: uil-ring-anim 1s linear infinite;
}

.alert {
    --bs-alert-bg: transparent;
    --bs-alert-padding-x: 1rem;
    --bs-alert-padding-y: 1rem;
    --bs-alert-margin-bottom: 1rem;
    --bs-alert-color: inherit;
    --bs-alert-border-color: transparent;
    --bs-alert-border: 1px solid var(--bs-alert-border-color);
    --bs-alert-border-radius: 0.375rem;
    position: relative;
    padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
    margin-bottom: var(--bs-alert-margin-bottom);
    color: var(--bs-alert-color);
    background-color: var(--bs-alert-bg);
    border: var(--bs-alert-border);
    border-radius: var(--bs-alert-border-radius)
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 3rem
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem
}

.alert-primary {
    --bs-alert-color: #084298;
    --bs-alert-bg: #cfe2ff;
    --bs-alert-border-color: #b6d4fe
}

.alert-primary .alert-link {
    color: #06357a
}

.alert-secondary {
    --bs-alert-color: #41464b;
    --bs-alert-bg: #e2e3e5;
    --bs-alert-border-color: #d3d6d8
}

.alert-secondary .alert-link {
    color: #34383c
}

.alert-success {
    --bs-alert-color: #0f5132;
    --bs-alert-bg: #d1e7dd;
    --bs-alert-border-color: #badbcc
}

.alert-success .alert-link {
    color: #0c4128
}

.alert-info {
    --bs-alert-color: #055160;
    --bs-alert-bg: #cff4fc;
    --bs-alert-border-color: #b6effb
}

.alert-info .alert-link {
    color: #04414d
}

.alert-warning {
    --bs-alert-color: #664d03;
    --bs-alert-bg: #fff3cd;
    --bs-alert-border-color: #ffecb5
}

.alert-warning .alert-link {
    color: #523e02
}

.alert-danger {
    --bs-alert-color: #842029;
    --bs-alert-bg: #f8d7da;
    --bs-alert-border-color: #f5c2c7
}

.alert-danger .alert-link {
    color: #6a1a21
}

.alert-light {
    --bs-alert-color: #636464;
    --bs-alert-bg: #fefefe;
    --bs-alert-border-color: #fdfdfe
}

.alert-light .alert-link {
    color: #4f5050
}

.alert-dark {
    --bs-alert-color: #141619;
    --bs-alert-bg: #d3d3d4;
    --bs-alert-border-color: #bcbebf
}

.alert-dark .alert-link {
    color: #101214
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}