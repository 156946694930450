.body1 {
    font-family: 'Arvo', serif;
    background: #ABCDEF;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    transition: 2s;
}

.pageError {
    color: #20293F;
    /* box-shadow: 0 5px 0px -2px #20293F; */
    text-align: center;
    animation: fadein 1.2s ease-in;
    /* margin: 20px; */
    padding: 20px;
}

.pageError>.code {
    font-size: 10.5em;
    text-shadow: 0 6px 1px rgba(0, 0, 0, 0.0980392), 0 0 5px rgba(0, 0, 0, 0.0980392), 0 1px 3px rgba(0, 0, 0, 0.298039), 0 3px 5px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.247059), 0 10px 10px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.14902);
    margin: 0;
}

.pageError>.not-found {
    text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5), 0px 6px 20px rgba(0, 0, 0, 0.3);
    font-weight: 400;
    /* margin: 20px 0 5px 0; */
}

.pageError>.desc {
    text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5), 0px 6px 20px rgba(0, 0, 0, 0.3);
    font-weight: 400;
    margin: 20px 0 20px 0;
}

.pageError .text-btn {
    text-align: center;
    display: flex;
    justify-content: center;
}

.pageError>.text-btn .btn-outline-primary {
    border-color: #537597;
    color: #699acb;
    border-radius: 0;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 5px 15px 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.pageError>.text-btn .btn-outline-primary:hover {
    background-color: #699acb;
    border-color: #f5faff;
    color: #141829;
}

@keyframes fadein {
    0% {
        margin-top: -50px;
        opacity: 0;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}